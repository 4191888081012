import React, { useEffect, useState } from 'react'
import './home.scss'
import Sound from 'react-sound-2';
import aduh from '../../assets/music/Aduh.mp3'
import kitaBikinRomantis from '../../assets/music/Kita-Bikin-Romantis.mp3'
import bersamamu from '../../assets/music/Bersamamu.mp3'
import { Opening } from 'components';
import Content from 'pages/Content';
import translationEN from 'libs/en/translation.json'
import translationID from 'libs/id/translation.json'
import { initReactI18next, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';

const playlist = [
    { src: aduh },
    { src: kitaBikinRomantis },
    { src: bersamamu}
]

const resources = {
    en: {
        translation: translationEN,
    },
    id: {
        translation: translationID,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

function Home() {
    const [songPlaying, setSongPlaying] = useState('')
    const [musicPlaying, setMusicPlaying] = useState('STOPPED')
    const [openingPage, setOpeningPage] = useState(true)
    const [guestName, setGuestName] = useState('')
    const [venue, setVenue] = useState('')
    const [guestVIP, setGuestVIP] = useState('false')
    const location = useLocation();
    const { i18n } = useTranslation();

    const getRandomObject = (array) => {
        const randomObject = array[Math.floor(Math.random() * array.length)];
        setSongPlaying(randomObject.src);
    };

    useEffect(() => {
        getRandomObject(playlist);
        const language = new URLSearchParams(location.search).get('lang');
        if (language !== null)
            i18n.changeLanguage(language.toLowerCase())
    }, [i18n, location.search]);

    useEffect(() => {
        const guestlink = new URLSearchParams(location.search).get('name');
        const venuelink = new URLSearchParams(location.search).get('venue');
        const viplink = new URLSearchParams(location.search).get('vip');
        setGuestName(guestlink)
        if (venuelink !== null)
            setVenue(venuelink.toLowerCase())
        setGuestVIP(viplink)
    }, [location.search])

    const handleMusic = (value) => {
        setMusicPlaying(value)
    }

    const handleOpeningPage = () => {
        setOpeningPage(!openingPage)
        handleMusic('PLAYING')
    }

    return (
        <div className='home'>
            {openingPage ?
                <Opening 
                    handleOpeningPage = {handleOpeningPage}
                    guestName = {guestName}
                />
                :
                <Content 
                    venue = {venue}
                    guestVIP = {guestVIP}
                    handleMusic = {handleMusic}
                />
            }
            {/* <div className='btn-music'>
                <div onClick={()=>setMusicPlaying('PLAYING')}>play</div>
                <div onClick={()=>setMusicPlaying('PAUSED')}>pause</div>
                <div onClick={()=>{setMusicPlaying('STOPPED'); getRandomObject(playlist)}}>stop</div>
            </div> */}
            <Sound
                volume={35}
                // playFromPosition={300}
                // onLoading={this.handleSongLoading}
                // onPlaying={this.handleSongPlaying}
                onFinishedPlaying={()=>{handleMusic('STOPPED')}}
                url = {songPlaying}
                playStatus={musicPlaying}
            />
        </div>
        
    )
}

export default Home