import React from 'react'
import './vip.scss'
import { Fade } from 'react-awesome-reveal'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next';

function VIP() {
    const { t } = useTranslation();
    return (
        <div className={'vip'}>
            <Fade duration={1200} delay={200} cascade triggerOnce>
                <div className='title'>
                    VIP
                </div>
                <Gap height={30}/>
                <div>
                    {t('vip_text_1')}
                </div>
                <Gap height={20}/>
                <div>
                    {t('vip_text_2')}
                </div>
            </Fade>
        </div>
    )
}

export default VIP