const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id, width, height) =>
    `https://aloveyu.com/images/${id}`;

const unsplashPhotos = [
    { id: "image-1.webp", width: 1080, height: 1620 },
    { id: "image-5.webp", width: 1620, height: 1080 },
    { id: "image-4.webp", width: 1620, height: 1080 },
    { id: "image-6.webp", width: 1620, height: 1080 },
    { id: "image-2.webp", width: 1080, height: 1620 },
    { id: "image-7.webp", width: 1620, height: 1080 },
    { id: "image-8.webp", width: 1620, height: 1080 },
    { id: "image-9.webp", width: 1620, height: 1080 },
    { id: "image-3.webp", width: 1080, height: 1620 },
    { id: "image-10.webp", width: 1620, height: 1080 },
    { id: "image-11.webp", width: 1620, height: 1080 },
    { id: "image-12.webp", width: 1620, height: 1080 },
    { id: "image-15.webp", width: 1080, height: 1620 },
    { id: "image-13.webp", width: 1620, height: 1080 },
    { id: "image-14.webp", width: 1620, height: 1080 },
];

const photos = unsplashPhotos.map((photo) => ({
    src: unsplashLink(photo.id, photo.width, photo.height),
    width: photo.width,
    height: photo.height,
    srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((photo.height / photo.width) * breakpoint);
        return {
            src: unsplashLink(photo.id, breakpoint, height),
            width: breakpoint,
            height,
        };
    }),
}));

export default photos;
