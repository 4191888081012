import React from 'react'
import './quote.scss'
import { Fade } from 'react-awesome-reveal'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next'

function Quote(props) {
    const { t } = useTranslation();

    return (
        <div className={'quote '+ props.type} id={props.id}>
            <Fade duration={1500} delay={300} cascade>
                <div className='title'>{props.title}</div>
                <Gap height={10}/>
                <div>{props.text}</div>
                {props.type === 'closing' &&
                    <>
                        <div className='name'>
                            Putri & Alvin
                        </div>
                        <div className='parent'>
                            {t('closing_family_1')}
                        </div>
                        <Gap height={8}/>
                        <div className='parent'>
                            {t('closing_family_2')}
                        </div>
                        <Gap height={30}/>
                        <div className='hashtag'>
                            #ALoveYU
                        </div>
                    </>
                }
            </Fade>
        </div>
    )
}

export default Quote