import React from 'react'
import './flyButton.scss'
import { CameraIcon, GreetingCard, HomeIcon, MarkerIcon, ThankYouIcon, UserIcon } from 'assets'
import { Link } from 'react-scroll'

function FlyButton() {
    return (
        <div className='fly-button'>
            <Link className='section' to='home' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={HomeIcon}/>
            </Link>
            <Link className='section' to='greeting' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={GreetingCard}/>
            </Link>
            <Link className='section' to='card-name' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={UserIcon}/>
            </Link>
            <Link className='section' to='menghitung-hari' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={MarkerIcon}/>
            </Link>
            <Link className='section' to='gallery' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={CameraIcon}/>
            </Link>
            <Link className='section' to='thank-you' spy={true} smooth={true} duration={200}>
                <img alt='icon' className='icon' src={ThankYouIcon}/>
            </Link>
        </div>
    )
}

export default FlyButton